:root {
  --color-blue-main: #18407E;
  --color-black: #000000;
  --color-grey: #808080;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
  color: #505050 !important;
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.state-input-group {
  position: relative;
}

.dropdown-icon,
.reset-state-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-icon {
  right: 10px;
}

.reset-state-btn {
  right: 30px;
}

.state-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.state-suggestions li {
  padding: 5px 10px;
  cursor: pointer;
}

.state-suggestions li:hover {
  background-color: #f0f0f0;
}

.terms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-image, .right-image{
  border-radius: 10%;
} 

.terms-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.terms-checkbox {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.terms-checkbox input[type="checkbox"] {
  width: 10%;
  margin-bottom: 0 !important;
}

.terms-checkbox label {
  font-size: 14px;
  line-height: 1;
}
.document-tabs {
  display: flex;
  margin-bottom: 10px;
}

.document-tabs button {
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.document-tabs button.active {
  background-color: var(--color-blue-main);
  color: white;
}

.document-content {
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.document-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

.terms-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .accept{
    background-color: var(--color-blue-main);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .decline{
    background-color: #f0f0f0;
    color: #505050;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.view-terms-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

nav.navbar {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background-color: #fff;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #ffffff;

  .profile-btn {
    color: var(--color-blue-main);
  }
}

nav.navbar.properties-top {
  background-color: transparent;
}

nav.navbar.properties-top a.navbar-brand,
nav.navbar.properties-top .navbar-nav .nav-link.navbar-link,
nav.navbar.properties-top .navbar-text button.profile-btn span {
  color: var(--color-blue-main);
}

nav.navbar.properties-top .navbar-text button.cta,
nav.navbar.properties-top .navbar-text button.profile-btn {
  border-color: var(--color-blue-main);
  background-color: var(--color-blue-main);
  color: #fff;
}


nav.navbar.scrolled {
  background-color: #ffffff;
}

nav.navbar.scrolled a.navbar-brand,
nav.navbar.scrolled .navbar-nav .nav-link.navbar-link,
nav.navbar.scrolled .navbar-text button.cta span,
nav.navbar.scrolled .navbar-text button.profile-btn span {
  color: var(--color-blue-main);

  
}
nav.navbar.scrolled .navbar-text button.cta:hover span,
nav.navbar.scrolled .navbar-text button.profile-btn:hover span{
  color: #fff !important;

}
nav.navbar.start:not(.scrolled) .profile-btn:hover {
  background-color: #fff;
  color: var(--color-blue-main) !important;
  border-color: #fff;
}
nav.navbar:not(.start) .profile-btn:hover {
  background-color: var(--color-blue-main);
}

nav.navbar:not(.start) .profile-btn:hover,
nav.navbar:not(.start) .profile-btn:hover svg,
nav.navbar:not(.start) .profile-btn:hover::after {
  color: #fff !important;
}

nav.navbar:not(.start) .profile-btn:hover .dropdown-toggle::after {
  border-top-color: #fff;
}

nav.navbar.start:not(.scrolled) .profile-btn:hover svg,
nav.navbar.start:not(.scrolled) .profile-btn:hover .dropdown-toggle::after {
  color: var(--color-blue-main);
}

nav.navbar.start:not(.scrolled) .profile-btn:hover::after {
  border-top-color: var(--color-blue-main);
}

nav.navbar.scrolled .navbar-text button.cta,
nav.navbar.scrolled .navbar-text button.profile-btn {
  border-color: var(--color-blue-main);
}

nav.navbar.home {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

nav.navbar.home a.navbar-brand {
  color: var(--color-blue-main);
}


nav.navbar.home .navbar-nav .nav-link.navbar-link {
  color: var(--color-grey) !important;
}

nav.navbar.home .navbar-nav .nav-link.navbar-link.active {
  color: var(--color-blue-main) !important; /* Sets text color */
  background-color: #3CA1D0 !important; /* Corrected 'background-colour' to 'background-color' */
  border-radius: 4px; /* Adds slight rounding for a polished look */
  padding: 8px 12px; /* Adjusts padding for better spacing */
  font-weight: bold; /* Highlights the active link */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
}


nav.navbar.scrolled a.navbar-brand {
  color: var(--color-blue-main);
}
nav.navbar.home .navbar-text button.cta span,
nav.navbar.home .navbar-text button.profile-btn span {
  color: var(--color-blue-main);
}

nav.navbar.home .navbar-text button.cta:hover,
nav.navbar.home .navbar-text button.profile-btn:hover {
  background-color: var(--color-blue-main);
  color: #fff !important;
}

nav.navbar.home .navbar-text button.cta:hover span,
nav.navbar.home .navbar-text button.profile-btn:hover span {
  color: #fff !important;
  
}

nav.navbar.scrolled .navbar-nav .nav-link.navbar-link {
  color: #333 !important;
}

nav.navbar.scrolled .navbar-nav .nav-link.navbar-link.scrolled {
  color: #333 !important;
}

nav.navbar.scrolled .navbar-text button.cta {
  color: var(--color-blue-main);
  border-color: var(--color-blue-main);
}

nav.navbar.scrolled .navbar-text button.cta::before {
  background-color: var(--color-blue-main);
}

nav.navbar.scrolled .navbar-text button.cta:hover {
  color: #fff;
}

nav.navbar a.navbar-brand {
  width: 20%;
  color: var(--color-blue-main);
  font-weight: 800;
  font-size: 2rem;
}
/* Navbar styles for home route (/) */
nav.navbar.start {
  background-color: transparent;
}

nav.navbar.start .navbar-nav .nav-link.navbar-link {
  color: #fff !important;
}

nav.navbar.start .navbar-text button {
  color: #fff;
  border-color: #fff;
}

nav.navbar.start .navbar-text button span {
  color: #fff;
}

/* Scrolled state */
nav.navbar.start.scrolled {
  background-color: var(--color-blue-main);
}

nav.navbar.start.scrolled .navbar-nav .nav-link.navbar-link {
  color: #fff !important;
}

nav.navbar.start.scrolled .navbar-text button {
  background-color: #fff;
  color: var(--color-blue-main);
  border-color: #fff;
}

nav.navbar.start.scrolled .navbar-text button span {
  color: var(--color-blue-main);
}

nav.navbar.start.scrolled a.navbar-brand {
  color: #fff;
}

@media (max-width: 767px) {
  nav.navbar.start .navbar-collapse {
    background-color: var(--color-blue-main);
  }
}

nav.navbar.start a.navbar-brand {
  color: #fff;
}
nav.navbar.start:not(.scrolled) .profile-btn {
  color: #fff !important;
}

nav.navbar.start:not(.scrolled) .profile-btn svg {
  color: #fff;
}
nav.navbar.start:not(.scrolled) .profile-btn {
  color: #fff !important;
  border-color: #fff;
}

nav.navbar.start:not(.scrolled) .profile-btn svg,
nav.navbar.start:not(.scrolled) .profile-btn::after {
  color: #fff;
  border-top-color: #fff;
}


nav.navbar.start:not(.scrolled) .dropdown-toggle::after {
  border-top-color: #fff;
}
nav.navbar.start .navbar-text button:hover span {
  color: var(--color-blue-main) !important;
}

nav.navbar.start .navbar-text button:hover {
  background-color: #fff;
  border-color: #fff;
  color: var(--color-blue-main) !important;
}

nav.navbar.start .navbar-toggler {
  border-color: #fff;
}

/* Maintaining color when scrolled */
nav.navbar.start.scrolled a.navbar-brand {
  color: #fff;
}

nav.navbar.start.scrolled .navbar-toggler {
  border-color: #fff;
}

nav.navbar.start .navbar-toggler {
  border-color: #fff;
}

nav.navbar.start .navbar-toggler-icon:after,
nav.navbar.start .navbar-toggler-icon:before {
  background-color: #fff;
}

nav.navbar.start .navbar-toggler-icon {
  border-bottom: 2px solid #fff;
}

nav.navbar.start .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after,
nav.navbar.start .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  background-color: #fff;
}

/* Media query for mobile collapse */
@media (max-width: 767px) {
  nav.navbar.start .navbar-collapse {
    background-color: var(--color-blue-main);
    margin-top: 1rem;
    border-radius: 8px;
    padding: 1rem;
  }
}
nav.navbar .navbar-nav .nav-link.navbar-link,
nav.navbar .navbar-nav .navbar-link {
  font-weight: 500;
  color: var(--color-blue-main) !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 1.6rem;
  opacity: 1;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active,
nav.navbar .navbar-nav a.navbar-link:hover,
nav.navbar .navbar-nav a.navbar-link.active {
    color:  #3CA1D0 !important;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 3px; /* Adds space between text and underline for modern look */
    transition: color 0.3s ease, text-decoration-color 0.3s ease; /* Smooth hover effect */
    text-decoration-color: #3CA1D0;
}

span.navbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-brand.agents-logo {
  color: var(--color-blue-main) !important; /* or your preferred shade of blue */
} 
.navbar-text button {
  font-weight: 700;
  color: var(--color-blue-main) !important;
  border: 2px solid var(--color-blue-main);
  padding: 18px 20px;
  font-size:1rem;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}



.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #fff;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler {
  border-color: var(--color-blue-main);
}

nav.navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(24, 64, 126, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 24px;
  height: 17px;
  position: relative;
  transition: all 300ms linear;
  top: -2px;
}



nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;

}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after,
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  background-color: var(--color-blue-main);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #000;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #000;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
nav.navbar .navbar-toggler-icon {
  background-color: transparent;
  border-bottom: 2px solid var(--color-blue-main);
}

nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
 
.forgot-password-link,
.back-to-login-link{
  text-decoration: none;
  display: flex;
  justify-content: flex-end; 
  margin-top: 10px;

  a{
    text-decoration: none;
    color: var(--color-blue-main);
  }
}



/* Navbar Media query */

@media (max-width: 1200px) {
  nav.navbar a.navbar-brand {
    font-size: 1.5rem;  
    width: auto;        
    word-break: break-word; 
  }
}


@media (max-width: 767px) {
  .navbar-collapse .nav-link {
    padding: 1rem 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .navbar-text {
    align-self: center !important;
  }
  .navbar-collapse {
    background-color: white;
    
  }
}

.password-reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.password-reset-card {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.password-reset-title {
  margin-bottom: 20px;
  text-align: center;
}

.password-reset-form {
  display: flex;
  flex-direction: column;
}

.password-reset-input {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.password-reset-button {
  background-color: var(--color-blue-main);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.password-reset-button:disabled {
  background-color: #6c757d;
}


/* HomeCover css */
.home-cover {
  background-image: url('./components/cover.svg');
  background-size: cover;
  background-position: center;
  min-height: 60vh;
  display: flex;
  align-items: center;
  padding: 10rem 0;
}

@media (max-width: 768px) {
  .home-cover {
    min-height: 60vh;
  }
}

.home-cover h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--color-blue-main);
}

@media (max-width: 768px) {
  .home-cover h1 {
    font-size: 2rem;
  }
}

.home-cover .form-control {
  height: 50px;
  font-size: 1rem;
  border-radius: 25px;
  padding: 0 20px;
}

.home-cover .btn-primary {
  height: 50px;
  font-size: 1rem;
  border-radius: 25px;
  padding: 0 30px;
}

@media (max-width: 768px) {
  .home-cover .btn-primary {
    width: 100%;
    margin-top: 1rem;
  }
}


/* Feed css */
.feed {
  padding: 1rem 0;
}

.property-card {
  margin-bottom: 30px;
  position: relative;
}

.property-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.property-card h3 {
  font-size: 18px;
  font-weight: bold;
}

.property-card.blurred::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  pointer-events: none;
  z-index: 1;
}

.upload-property-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 310px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.plus-icon {
  font-size: 4rem;
  color: var(--color-grey);
  align-self: center;

}

.upload-property-card p {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-grey);
  align-items: center;

}


.subscribe-overlay .btn-primary {
  font-size: 18px;
  font-weight: bold;
  padding: 12px 30px;
  border-radius: 30px;
  background-color: var(--color-blue-main);
}

/* Feature Cards css */
.features {
  padding: 60px 0;
}

.feature-card {
  border: 2px solid var(--color-blue-main);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card .card-img-top {
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.feature-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--color-blue-main);
}

.feature-card .card-text {
  font-size: 1rem;
  color: var(--color-blue-main);
}

/* Contact Banner */
.contact-banner {
  background-color: var(--color-blue-main);
  padding: 60px 0;
  color: #fff;
}

.contact-banner h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contact-banner p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.contact-banner a {
  color: #fff;
  text-decoration: none;
}

.contact-banner a:hover {
  text-decoration: underline;
}

.contact-banner .btn-light {
  background-color: #fff;
  color: var(--color-blue-main);
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 30px;
  border-radius: 30px;
  margin-top: 1rem;
}

.contact-banner .btn-light:hover {
  background-color: #f2f2f2;
}
.send-message-btn {
  background-color: var(--color-blue-main) !important;
  border: none;
  color: white; 
}



/* Popup css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-container {
  display: flex;
  position: relative;
  width: 800px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
}

.popup-content {
  flex: 1;
  background-color: #fff;
  padding: 40px;
  flex-direction: column;

}

.popup-content h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: var(--color-black);

  span {
    color: var(--color-blue-main);
  }
}

.popup-content p {

  margin-bottom: 20px;
  text-align: center;
  color: var(--color-black);
}

.heading-popup {
  font-size: 1.5rem;
  font-weight: 500;
}


.signup-options form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.signup-options form input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--color-blue-main);
  font-size: 16px;
}

.create-account-btn {
  width: 100%;

}
.password-requirements {
  font-size: 0.9em;
  margin-top: 10px;
}

.password-requirements ul {
  list-style-type: none;
  padding-left: 0;
}

.password-requirements li {
  margin-bottom: 5px;
}

.password-requirements .met {
  color: green;
}

.password-requirements .unmet {
  color: red;
}

.invalid-password {
  border: 2px solid red !important;
  background-color: #ffebeb;
}

.password-input-group {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.google-btn {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid var(--color-blue-main);
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  color: var(--color-grey);
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.google-btn .google-icon {
  margin-right: 8px;
}

.separator {
  text-align: center;
  margin: 1rem 0;
  font-weight: 100;
  font-size: 1.5rem;
}

.form-elements {
  display: flex;
  flex-direction: column;

  label {
    color: #717171;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  textarea {
    min-height: 100px;
    padding: 10px;
    border: 1px solid var(--color-blue-main);
    margin-top: 1rem;
  }
}

.upload-area {
  padding: 50px;
  border: 1px solid var(--color-blue-main);
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  cursor: pointer;

  .upload-icon {
    font-size: 4rem;
  }

  p {
    color: #717171;
    font-size: 1.2rem;
    vertical-align: middle;
    flex-wrap: wrap;
    width: 50%;
  }
}

input {
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--color-blue-main);
  font-size: 16px;
}
.signin-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.signin-input-group {
  margin-bottom: 1rem;
  width: 100%;
}

.signin-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--color-blue-main);
  font-size: 16px;
}

.signin-button {
  width: 100%;
  padding: 10px;
  background-color: var(--color-blue-main);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 600;
}

.signin-separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.signin-separator::before,
.signin-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.signin-separator span {
  padding: 0 10px;
  color: var(--color-grey);
  font-weight: 500;
}

.google-signin-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.google-signin-button {
  width: 100%;
}

.create-account-btn,
.sign-in-btn,
.submit-btn {
  padding: 10px;
  background-color: var(--color-blue-main);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 600;
}

.login-link,
.signup-link {
  text-align: center;
  margin-top: 20px;
  color: var(--color-grey) !important;
  font-weight: 500;

  a {
    text-decoration: none;
    color: var(--color-blue-main);
    font-weight: 600;
  }
}

.membership-section {
  margin-top: 10px;
  text-align: center;
  border: 1px solid var(--color-blue-main);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;

  img {
    width: 30%;
  }
}

.membership-section p {
  font-size: 18px;
  margin-bottom: 10px;
}

.subscribe-button {
  padding: 10px 20px;
  background-color: var(--color-blue-main);
  color: #fff;
  border: 2px solid var(--color-blue-main);
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
}

.popup-right {
  position: relative;
  width: 40%;
  background-image: url('./components/cover.svg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
  padding: 5px;
}

.popup-right-content {
  text-align: left;
  color: #fff;
  padding-top: 2rem;
}


.filler-text {
  font-size: 2rem;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-black);
}
/* Pop up media query */
@media (max-width: 1200px) {
  .popup-container {
    max-width: 1000px;
  }
}

@media (max-width: 1024px) {
  .popup-container {
    max-width: 900px;
  }
}

@media (max-width: 900px) {
  .popup-container {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .popup-container {
    max-width: 600px;
    flex-direction: column;
  }

  .popup-content {
    padding: 20px;
  }

  .popup-right {
    order: -1;
    width: 100%;
    height: auto;
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .popup-container {
    max-width: 500px;
  }

  .popup-content {
    padding: 15px;
  }

  .popup-right {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .popup-container {
    max-width: 400px;
  }

  .popup-content {
    padding: 10px;
  }

  .popup-right {
    padding: 10px;
  }
}

@media (max-width: 320px) {
  .popup-container {
    max-width: 100%;
  }

  .popup-content {
    padding: 5px;
  }

  .popup-right {
    padding: 5px;
  }
}



/** Profile card css **/

.profile-card {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.profile-grid {
  margin-top: 2rem;
}

.options {
  text-decoration: none;
  font-size: 1.5rem;
  color: #505050;

  ul {
    list-style: none;
  }

  li {
    margin-bottom: 1.5rem;
  }

  li.active {
    background-color: var(--color-blue-main);
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-radius: 8px;
  }
}

.upload-item {
  margin-bottom: 2rem;
}

.upload-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.upload-item h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

table th {
  font-weight: bold;
}

.payment-info {
  padding: 1rem;
  border: 1px solid var(--color-blue-main);
  border-radius: 8px;
  margin-bottom: 1rem;

  Button {
    background-color: var(--color-blue-main);
    border: 1px solid var(--color-blue-main);
    margin-bottom: 0.5rem;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.pagination .page-item {
  margin: 0 0.5rem;
}

.pagination .page-link {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-blue-main);
  border-radius: 4px;
  color: var(--color-blue-main);
  background-color: #fff;
  cursor: pointer;
}

.pagination .page-link:hover {
  background-color: var(--color-blue-main);
  color: #fff;
}

.pagination .page-item.active .page-link {
  background-color: var(--color-blue-main);
  color: #fff;
}

/** Payment css **/
.payment-popup {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
}

.payment-popup h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-black);
}

.payment-options {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.payment-options img {
  width: 60px;
  margin: 0 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-grey);
  font-size: 0.9rem;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--color-blue-main);
  border-radius: 4px;
  font-size: 1rem;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.cancel-btn,
.save-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.cancel-btn {
  background-color: transparent;
  border: none;
  color: var(--color-grey);
  margin-right: 1rem;
}

.save-btn {
  background-color: var(--color-blue-main);
  color: #fff;
  border: none;
}

/** Landing css **/

.hero-content h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--color-blue-main);
}

.hero-content p {
  font-size: 1.4rem;
  margin-bottom: 30px;
  color: var(--color-grey);
  text-wrap: wrap;
  width: 90%;
  text-align: center;
}

.hero-content {
  text-align: center;
  padding-top: 2rem;
}

.connections-container {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0.5rem;
}

.hero-image {
  width:30rem;
  height: 6rem;
  border-radius: 30px;
  margin-left: 1rem;
  object-fit: cover;
  border: 2px solid #18407E;
}

.cta-btn {
  display: inline-flex;
  justify-content: left;
  background-color: transparent;
  color: var(--color-black);
  border: 1px solid var(--color-blue-main);
  border-radius: 30px;
  padding: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: light;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.cta-arrow-btn{
  padding: 0.5rem 1.5rem;
  size:2rem;
  border: 2px solid var(--color-blue-main);
  margin-left: -20px;
  border-radius: 20px;
  background-color: var(--color-blue-main);
  color: #fff;
}

.cta-btn:hover {
  background-color: var(--color-blue-main);
  color: #fff;
}

.right-content {
  text-align: center;
  padding-right: 2rem;
  color: var(--color-black);

}

.right-content p {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  max-width: 90%;
}

.filler-content {
  padding-left: 4rem;
  color: var(--color-black);
}



.filler-content ul {
  list-style-type: none;
  padding: 0;
}

.filler-content li {
  margin-bottom: 1rem;
}

.filler-content strong {
  font-size: 1.5rem;
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.filler-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: 300;
}

.cta-btn {
  font-size: 1.2rem;
  padding: 1rem 2rem;
}



.home-imagelay {
  position: relative;
  padding-top: 2rem;
}

.home-imagelay .row {
  position: relative;
  z-index: 1;
}
.blue-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10rem;
  height: 10rem;
  background-color: var(--color-blue-main);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.inner-circle {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-icon {
  color: #fff;
  font-size: 3rem;
}

.d-flex.align-items-center {
  min-height: 50px; 
}

.works-section {
  padding-top: 4rem;
  h2{
    text-align: center;
    font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 2rem;

  }
}
.works-section .card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.works-section .card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.works-section .card-body {
  padding: 2rem;
}

.works-section .card-title {
  margin-bottom: 1.5rem;
}

.works-section .card-text {
  line-height: 1.6;
}

.circle-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #18407E;
  color: white;
  font-size: 1.5rem;
  border-radius: 50%; 
  margin-bottom: 10px; 
}

.exist-section {
  padding-top: 4rem;
  h2{
    text-align: center;
    font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 2rem;

  }
}
.exist-section .card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.exist-section .card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.exist-section .card-body {
  padding: 2rem;
}

.exist-section .card-title {
  margin-bottom: 1.5rem;
}

.exist-section .card-text {
  line-height: 1.6;
}

.card-icon-container {
  margin-bottom: 2rem;
}

.card {
  border: none;
  text-align: center;
  margin-bottom: 2rem;
}

.card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 25%;
  background-color: var(--color-blue-main);
  margin: 0 auto 1.5rem;
  margin-top: 1rem;
}

.card-icon {
  font-size: 2.5rem;
  color: #fff;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1rem;
  color: #777;
}
.pricing-section {
  padding-top: 2rem;
  text-align: center;
}

.pricing-section h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.pricing-card {
  border: 2px solid var(--color-blue-main);
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: #f8f9fa;
  color: #000;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pricing-card .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pricing-card:hover {
  background-color: var(--color-blue-main);
  color: #fff;
}

.pricing-card:hover .card-title,
.pricing-card:hover .card-text,
.pricing-card:hover .price,
.pricing-card:hover .placeholder-text,
.pricing-card:hover .pricing-features li {
  color: #fff;
}

.pricing-card .card-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.placeholder-text {
  margin-bottom: 1rem;
}
.free-plan-button {
  background-color: transparent !important;
  color: var(--color-blue-main) !important;
  border-color: var(--color-blue-main) !important;
}

.pricing-button {
  background-color: var(--color-blue-main) !important;
  border-color: var(--color-blue-main) !important;
  color: #fff !important;
}

.pricing-button {
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  border-radius: 30px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pricing-card:hover .pricing-button {
  background-color: #fff !important;
  color: var(--color-blue-main) !important;
  border-color: #fff !important;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid #ccc;
}

.pricing-features {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.pricing-features li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.pricing-check-icon {
  color: var(--color-blue-main);
  margin-right: 0.5rem;
  transition: color 0.3s ease;
}

.pricing-card:hover .pricing-check-icon {
  color: #fff;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 40px;
  }

  .hero-image {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
  .connections-container {
    display: none; 
  }

  .hero-image {
    display: none; 
  }

  .home-imagelay .row {
    flex-direction: column;
  }

  .filler-content, .right-content {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }

  .filler-content ul {
    padding-left: 0;
  }

  .filler-content li {
    margin-bottom: 2rem;
  }

  .blue-circle {
    display: none;
  }

  .cta-btn, .cta-arrow-btn {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1200px) {
  .blue-circle {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .filler-content li p {
    display: none;
  }

  .works-section .card {
    height: 100%;
  }

  .works-section .row {
    display: flex;
    flex-wrap: wrap;
  }

  .works-section .col-md-4 {
    display: flex;
    margin-bottom: 20px;
  }

  .works-section .card {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .works-section .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .contact-banner {
    text-align: center;
  }

  .contact-banner .btn-light {
    margin-top: 1rem;
  }

  .contact-banner .text-end {
    text-align: center !important;
  }
  
}
.start-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #18407E 0%, #0B1F3E 100%);
  display: flex;
  flex-direction: column;
  color: #fff;
}

.start-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
  background: rgba(255, 255, 255, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 2rem;
  font-weight: 800;
}

.agent-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.auth-button {
  padding: 0.75rem 2rem;
  border: 2px solid #fff;
  border-radius: 30px;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.auth-button:hover {
  background: #fff;
  color: #18407E;
}

.start-main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
}

.start-hero {
  max-width: 800px;
  text-align: center;
}

.start-icon {
  width: 10rem;
  height: 10rem;
  margin: 0 auto 3rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;           /* Added to center the logo */
  align-items: center;     /* Added to center vertically */
  justify-content: center; /* Added to center horizontally */
}

.start-icon img {
  width: 100%;            /* Make the image fill the container */
  height: 100%;           /* Make the image fill the container */
  object-fit: contain;    /* Maintain aspect ratio */
}

.start-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  line-height: 1.2;
}

.start-description {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3rem;
  line-height: 1.6;
}

.start-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.start-button {
  padding: 1rem 2.5rem;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
}

.start-button.primary {
  background: #fff;
  color: #18407E;
  border: 2px solid #fff;
}

.start-button.primary:hover {
  background: #18407E;
  color: #fff;
}

.start-button.secondary {
  border: 2px solid #fff;
  color: #fff;
}

.start-button.secondary:hover {
  background: #fff;
  color: #18407E;
}

.start-footer {
  padding: 2rem;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-nav {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 1.5rem;
}

.footer-nav a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-nav a:hover {
  color: #fff;
}

.copyright {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .start-header {
    padding: 1.5rem;
  }

  .logo {
    font-size: 1.5rem;
  }

  .start-title {
    font-size: 2.5rem;
  }

  .start-description {
    font-size: 1.2rem;
  }

  .start-buttons {
    flex-direction: column;
  }

  .footer-nav {
    flex-direction: column;
    gap: 1rem;
  }
}